// *** STYLES ***
* {
    font-family: "Raleway", sans-serif;
    // color: $primary;
}

html,
body {
    margin: 0;
    padding: 0;
    background: $primary;
    color: $white;
}

* {
    color: $primary;
}

.no-scroll-bar {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
    }
}

.page-wrapper {
    position: relative;
    height: 100vh;
    padding: 0;
}

.container {
    padding: 0 20px;
    width: calc(100% - 40px);

    @media (min-width: $screen-l) {
        padding: 0 80px;
        width: calc(100% - 200px);
    }

    @media (max-width: $screen-m) {
        padding: 0 20px;
        width: calc(100% - 40px);
    }
}

.flex {
    display: flex;

    &.align {
        align-items: center;
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

span:not(.emoji) {
    display: block;
    margin: 0;
}

span.text {
    display: inline;
    z-index: 10;
    position: relative;
}

.shadow {
    -webkit-box-shadow: $shadow;
    box-shadow: $shadow;
}

button.default {
    background: none;
    border: 0;
    font-weight: 600;
    cursor: pointer;
    padding: 8px 25px;
    outline: none;
    position: relative;
    overflow: hidden;
    font-size:12px;

    &::before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $secondary;
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.1s;
        transition-duration: 0.1s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    &.primary {
        color: $white;
        border: 2px solid $secondary;
    }

    &:hover * {
        color: $white !important;
    }

    &.secondary {
        * {
            color: $black;
        }
        border: 2px solid $tertiary;

        &::before {
            background: $tertiary;
        }
    }

    .main-text {
        position: relative;
        z-index: 2;
    }

    &:hover::before {
        transform: scaleY(1);
    }
}

.highlighter {
    position: relative;
    display: inline;

    .text {
        z-index: 10;
        display: inline;
        position: relative;
    }

    .highlight {
        width: 96%;
        background: $secondary;
        display: block;
        left: -2px;
        position: absolute;
        z-index: 5;
        height: 0;
        bottom: 0;
    }

    &:hover,
    &.selected {
        .highlight {
            height: 8px;
        }
    }
}

.transition {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.rounded-s {
    border-radius: $border-radius-s;
    -webkit-border-radius: $border-radius-s;
}

.rounded-m {
    border-radius: $border-radius-m;
    -webkit-border-radius: $border-radius-m;
}

.rounded-l {
    border-radius: $border-radius-l;
    -webkit-border-radius: $border-radius-l;
}

.rounded-xl {
    border-radius: $border-radius-xl;
    -webkit-border-radius: $border-radius-xl;
}

a {
    color: $white;
    text-decoration: none;
}

h1 {
    color: $primary;
    font-size: 60px;

    .highlighter {
        display: inline;

        .highlight {
            height: 20px !important;
            bottom: 5px;
        }
    }

    @media (max-width: $screen-s) {
        font-size: 45px;

        .highlighter .highlight {
            height: 15px !important;
        }
    }
}

.chat-bubble {
    background: $white;
    padding: 8px 14px;
    font-weight: 600;
    text-align: left;
    font-size: 15px;
    align-items: center;

    svg {
        fill: $grey;
        height: 10px;
        display: block;
        padding: 5px 0;
        width: 100%;
    }

    &::after {
        content: "";
        width: 20px;
        height: 13px;
        background: url("../resources/images/chat-cross.png");
        background-size: contain !important;
        background-repeat: no-repeat !important;
        display: block;
        position: absolute;
    }

    &.left::after {
        left: -8px;
    }

    &.right::after {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
    }
}

.dot-navigation {
    position: fixed;
    z-index: 500;
    top: calc((100vh - 100px) / 2);
    right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .dot {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        cursor: pointer;
        border: 2px solid $secondary;

        &.selected {
            background: $secondary;
            -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 3px 6px 0 rgba(255, 159, 28, 0.16);
        }

        .overlay {
            right: 30px;
            margin-top: -13px;
            white-space: nowrap;
            position: absolute;
            background: $primary;
            font-size: 12px;
            font-weight: 600;
            display: none;
            color: $white;
            padding: 10px 20px;
        }

        &:hover {
            .overlay {
                display: block;
            }
        }
    }
}

.opacity-fade-effect {
    opacity: 0;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;

    &.visible {
        opacity: 1;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
    }
}
