.work {
    .project {
        display: flex;
        justify-content: space-between;
        margin-top: 100px;

        &:nth-of-type(2n) {
            flex-direction: row-reverse;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        .slide {
            display: flex;
            background: none;
            align-items: center;
        }

        .slider-container {
            width: calc(50% - 40px);
            align-self: flex-start;
            padding: 10px;
            overflow: scroll;

            .item {
                max-height: 400px;
                margin: auto;

                img {
                    height: 100%;
                }

                video {
                    width: 100%;
                    height: 100%;
                    outline: none;
                }

                &.youtube {
                    & > div {
                        margin: 0;
                        display: block;

                        iframe {
                            display: block;
                            max-width: 100%;
                            max-height: 100%;
                            margin: 0;
                            width: 5000px;
                            height: 400px;
                            border-radius: 10px;
                        }
                    }
                }

                .info {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: $white;
                    padding: 7px 10px;
                    border-radius: 100px;
                    display: flex !important;
                    align-items: center;

                    .icon {
                        width: 15px;
                        height: 15px;
                        margin-right: 10px;
                        background: url('../resources/images/info.png');
                        background-size: cover;
                    }

                    span {
                        font-size: 13px;
                        font-weight: 500;
                        color: $primary;
                    }
                }
            }
        }

        .details {
            width: calc(50%);

            .title {
                margin: 0;

                h2 {
                    margin: 0;
                    padding: 0;
                    font-size: 35px;
                    display: flex;
                    flex-wrap: wrap;
                }

                .tiret {
                    margin: 0 10px;

                    .highlight {
                        height: 5px;
                        width: 80%;
                        bottom: 13px;
                        left: 0;
                    }
                }
            }

            .description {
                margin: 10px 0;
                max-height: 200px;
                overflow: hidden;
                position: relative;

                &.extended {
                    max-height: none;

                    padding-bottom: 15px;
                }

                p {
                    white-space: pre-line;
                }

                .see-more {
                    width: 100%;
                    height: 30px;
                    position: absolute;
                    bottom: 0;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    background: rgb(255, 255, 255);
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
                    cursor: pointer;

                    .bar {
                        background: $black;
                        width: 20px;
                        height: 6px;

                        .highlight {
                            height: 4px;
                            width: calc(100% - 2px);
                        }
                    }

                    span {
                        font-weight: 700;
                        text-transform: uppercase;
                        font-size: 10px;
                        margin: 0 15px;
                    }

                    &:hover {
                        span {
                            text-decoration: underline;
                        }
                    }
                }
            }

            h4 {
                text-transform: uppercase;
                font-size: 12px;
                margin-top: 30px;
                font-weight: 700;

                &:first-of-type {
                    margin-top: 30px;
                }
            }
        }

        @media (max-width: $screen-xl) {
            flex-direction: column;

            .slider-container {
                width: calc(100% - 20px);
                margin-bottom: 20px;
            }

            .details {
                width: 100%;
            }

            &:nth-of-type(2n) {
                flex-direction: column;
            }
        }

        .carousel .control-dots {
            display: flex;
            justify-content: center;
            padding: 0 10px;
            bottom: 0;

            .dot {
                width: 100px;
                height: 4px;
                border-radius: 2px;
                -webkit-box-shadow: none;
                box-shadow: none;
                outline: none;
                background: $white;
            }
        }

        .carousel.carousel-slider .control-arrow:hover {
            background: none;
            opacity: 1;
        }

        .carousel .control-arrow,
        .carousel.carousel-slider .control-arrow {
            opacity: 1;
            -webkit-transition: all 0.2s ease;
            -moz-transition: all 0.2s ease;
            -ms-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        .carousel.carousel-slider .control-arrow {
            height: 50px;
            top: calc((100% - 50px) / 2);
        }
        .control-arrow.control-prev {
            left: -50px;
        }

        .control-arrow.control-next {
            right: -50px;
        }

        .carousel-slider:hover {
            .control-arrow.control-prev {
                left: 0;
            }

            .control-arrow.control-next {
                right: 0;
            }
        }

        .carousel .control-next.control-arrow:before,
        .carousel .control-prev.control-arrow:before {
            border: none;
            background-color: $white;
            background-image: url('../resources/images/arrow.png');
            background-size: 50%;
            background-repeat: no-repeat;
            width: 35px;
            height: 35px;
            opacity: 1;
            border-radius: 17.5px;
            -webkit-box-shadow: $shadow;
            box-shadow: $shadow;
        }

        .carousel .control-next.control-arrow:before {
            background-position: 8px 10px;
            -moz-transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
        }

        .carousel .control-prev.control-arrow:before {
            background-position: 8px 10px;
            -moz-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }

    .fields {
        display: flex;
        align-items: center;
        position: relative;
        flex-wrap: wrap;

        span {
            margin-right: 20px;
            padding: 6px 25px;
            border-radius: 20px;
            color: $white;
            font-weight: 600;
            font-size: 13px;
            position: relative;
            margin-bottom: 10px;
        }
    }

    .challenges {
        li {
            font-weight: 300;
            font-size: 18px;

            &::before {
                content: '🚀';
                margin-right: 5px;
            }
        }
    }

    .tech {
        display: flex;
        flex-wrap: wrap;

        img {
            display: block;
            height: 25px;
            margin: 0 15px 0 0;
            cursor: pointer;
        }
    }

    a.app-link {
        color: $primary;
        margin-top: 40px;
        display: inline-block;
        font-weight: 700;
    }

    .more-button {
        text-align: center;
        margin-top: 20px;
    }
}
