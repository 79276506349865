// *** VARIABLES ***
// Screen size
$screen-s: 576px;
$screen-m: 768px;
$screen-l: 992px;
$screen-xl: 1200px;

$border-radius-s: 8px;
$border-radius-m: 12px;
$border-radius-l: 18px;
$border-radius-xl: 40px;

$primary: #011627;
$secondary: #FF9300;
$tertiary: #41ead4;
$white: #fff;
$black: #000;
$light-grey: #dfe4ea;
$grey: rgba($color: #011627, $alpha: 0.1);

$shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// *** IMPORTS ***
@import "./global.scss";
@import "./header.scss";
@import "./app.scss";
@import "./home.scss";
@import "./work.scss";
@import "./skill.scss";
@import "./method.scss";
@import "./contact.scss";
