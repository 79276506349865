.methods {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .method {
        padding: 30px;
        max-width: 300px;

        .icon {
            height: 100px;
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: contain !important;
        }

        &:first-of-type .icon {
            background: url("../resources/images/prototype.png");
        }

        &:nth-of-type(2) {
            .icon {
                background: url("../resources/images/dev.png");
            }

            margin: 0 50px;
        }

        &:last-of-type .icon {
            background: url("../resources/images/deploy.png");
        }

        h3 {
            font-size: 25px;
            text-align: center;
            margin: 20px 0;
        }
    }

    @media (max-width: $screen-xl) {
        flex-wrap: wrap;

        .method {
            max-width: none;
            width: 100%;

            &:nth-of-type(2) {
                margin: 20px 0;
            }
        }
    }
}
