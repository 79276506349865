.skills {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;

    @media (max-width: $screen-l) {
        justify-content: space-between;
    }

    .skill {
        padding: 20px;
        align-self: flex-start;
        min-width: 250px;
        margin-right: 20px;
        margin-bottom: 20px;

        @media (max-width: $screen-l) {
            min-width: 0;
            width: calc(50% - 50px);
            margin-right: 0;
        }
    }

    img {
        height: 25px;
        display: block;
        margin: 0;
    }

    span {
        margin: 10px 0 15px 0;
        font-size: 18px;
        font-weight: 600;
    }

    .progress {
        display: flex;

        .bar {
            width: 35px;
            background: $grey;
            height: 5px;
            margin-right: 10px;
            border-radius: 2.5px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        &.low {
            .bar {
                &:first-of-type {
                    background: #ff4757;
                }
            }
        }

        &.medium {
            .bar {
                &:first-of-type,
                &:nth-last-of-type(2) {
                    background: $secondary;
                }
            }
        }

        &.high {
            .bar {
                background: $tertiary;
            }
        }
    }
}
