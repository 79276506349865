.contact {
    color: $white;
    text-align: center;

    .face {
        padding: 20px;
        background: $secondary;
        margin: auto;
        border-radius: 100%;
        display: inline-block;
        position: relative;

        .img {
            width: 140px;
            height: 140px;
            background: url("../resources/images/face.png");
            background-size: cover;
        }

        .chat-bubble {
            position: absolute;
            bottom: 65%;

            @media (min-width: $screen-s) {
                min-width: 160px;
            }

            left: 170px;

            &::after {
                bottom: 0;
            }

            span.emoji {
                position: absolute;
                margin-top: -5px;
                margin-left: 5px;
            }
        }
    }

    .social {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;

        a {
            padding: 10px;
            background: $white;
            margin-right: 20px;
            margin-bottom: 20px;

            &:last-of-type {
                margin-right: 0;
            }

            img {
                width: 20px;
                height: 20px;
                display: block;
                margin: 0;
            }
        }
    }

    .chat {
        width: calc(100% - 20px);
        background: $white;
        max-width: 600px;
        margin: 30px auto 0;
        padding: 20px 10px;
        text-align: left;
        overflow: auto;
        max-height: 400px;

        .messages-container {
            display: flex;
            flex-direction: column;
            position: relative;
            padding-left: 35px;
            margin-top: 10px;

            &:first-of-type {
                margin-top: 0;
            }

            .sender-profile {
                padding: 5px;
                background: $secondary;
                position: absolute;
                bottom: -5px;
                left: 0;
                border-radius: 100%;

                .icon {
                    width: 15px;
                    height: 15px;
                    background: url("../resources/images/face.png");
                    background-size: cover;
                }
            }

            .messages {
                .message-container {
                    margin-bottom: 2px;

                    &:last-of-type {
                        .chat-bubble::after {
                            display: block;
                        }
                    }
                }

                .chat-bubble {
                    display: inline-block;
                    background: $light-grey;
                    color: $primary;
                    font-weight: 500;
                    font-size: 14px;
                    animation: fadeInUp 0.5s;
                    white-space: pre-line;
                    max-width: calc(50%);

                    .Typist {
                        color: $white;
                    }

                    @media (max-width: $screen-s) {
                        max-width: 100%;
                    }

                    .icon {
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                        margin-right: 8px;
                        background-size: cover !important;
                    }

                    a {
                        color: $primary;
                    }

                    &::after {
                        display: none;
                        width: 15px;
                        height: 8px;
                        left: 30px;
                        background: url("../resources/images/chat-cross-grey.png");
                    }
                }
            }

            form {
                display: flex;
                justify-content: space-between;
                align-items: center;

                input {
                    outline: none;
                    border: none;
                    background: none;
                    font-weight: 600;
                    flex: 1;
                    max-width: 100%;
                }

                button {
                    margin: 0;
                    padding: 0;
                    border: 0;
                    background: url("../resources/images/check-white.png");
                    background-size: cover;
                    width: 15px;
                    height: 15px;
                    cursor: pointer;
                }
            }

            .choices-container {
                display: flex;
                justify-content: flex-end;
                animation: fadeInUp 0.5s;

                .choices {
                    max-width: 70%;
                    text-align: right;

                    button {
                        margin: 0;
                        padding: 8px 15px;
                        margin-left: 10px;
                        margin-top: 10px;
                    }
                }
            }

            &.right {
                padding-left: 0;
                padding-right: 5px;
                text-align: right;

                .sender-profile {
                    display: none;
                }

                .message-container .chat-bubble {
                    background: $tertiary;
                    color: $primary;

                    &::after {
                        left: auto;
                        right: 0;
                        background: url("../resources/images/chat-cross-turquoise.png");
                        -moz-transform: scale(-1, 1);
                        -webkit-transform: scale(-1, 1);
                        -o-transform: scale(-1, 1);
                        -ms-transform: scale(-1, 1);
                        transform: scale(-1, 1);
                    }
                }
            }
        }
    }
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
// .animate__fadeInUp {
//     -webkit-animation-name: fadeInUp;
//     animation-name: fadeInUp;
// }
